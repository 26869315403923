import React from "react";
import elderser from "./images/elderlyser.jpg";
import freddie from "./images/freddie-jewel.jpg";
import schofield from "./images/ms-schofield.jpg";
import person from "./images/person_1.jpg";
import meal from "./images/pricing-caregiver-meal.jpg";
import walker from "./images/pricing-caregiver-walker.jpg";
import insta from './images/insta.png';
import "./components/stylesComponents.css"; // Ensure you have this CSS file with the styles provided

const Footer = () => (
  // <div className="site-footer bg-light">
  //   <div className="container">
  //     <div className="row">
  //       <div className="col-lg-4">
  //         <h2 className="footer-heading mb-4">About</h2>
  //         <p>At Emerald Care Group, we are dedicated to providing compassionate and personalized care for the elderly. Our mission is to ensure that each resident feels valued, supported, and able to live their life to the fullest in a safe and nurturing environment.</p>
  //         <div className="my-5 social">
  //           <a href="/" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
  //           <a href="/" className="pl-3 pr-3"><span className="icon-twitter"></span></a>
  //           <a href="/" className="pl-3 pr-3"><span className="icon-instagram"></span></a>
  //           <a href="/" className="pl-3 pr-3"><span className="icon-linkedin"></span></a>
  //         </div>
  //       </div>
  //       <div className="col-lg-8">
  //         <div className="row">
  //           <div className="col-lg-4">
  //             <h2 className="footer-heading mb-4">Quick Links</h2>
  //             <ul className="list-unstyled">
  //               <li><a href="/">Amazing Atmosphere</a></li>
  //               <li><a href="/">Caring Staff</a></li>
  //               <li><a href="/">FAQs</a></li>
  //               <li><a href="/">About Us</a></li>
  //               <li><a href="/">Contact Us</a></li>
  //             </ul>
  //           </div>
  //           <div className="col-lg-4">
  //             <h2 className="footer-heading mb-4">Helpful Links</h2>
  //             <ul className="list-unstyled">
  //               <li><a href="/">Hospice Care</a></li>
  //               <li><a href="/">Excellent Cuisine</a></li>
  //               <li><a href="/">Privacy</a></li>
  //               <li><a href="/">Terms</a></li>
  //             </ul>
  //           </div>
  //           <div className="col-lg-4">
  //             <h2 className="footer-heading mb-4">Our Services</h2>
  //             <ul className="list-unstyled">
  //               <li><a href="#">Personal care assistance</a></li>
  //               <li><a href="#">Medication management and administration</a></li>
  //               <li><a href="#">Meal planning and preparation</a></li>
  //               <li><a href="#">Housekeeping and laundry services</a></li>
  //               <li><a href="#">Social and recreational activities</a></li>
  //               <li><a href="#">Transportation to medical appointments</a></li>
  //               <li><a href="#">24/7 staff support and supervision</a></li>
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="row text-center">
  //       <div className="col-md-12">
  //         <div className="border-top pt-5">
  //           <p className="copyright">
  //             <small>
  //               Copyright &copy;{new Date().getFullYear()} All rights reserved | This template is made with <i className="icon-heart text-danger" aria-hidden="true"></i> by <a href="https://theemeraldcaregroup.com" target="_blank" rel="noopener noreferrer">The Emerald IT Team</a>
  //             </small>
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>

  <footer className="kilimanjaro_area">
    <div className="foo_top_header_one section_padding_100_70">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="kilimanjaro_part">
              <h5>About Us</h5>
              <p>
                At Emerald Care Group, we are dedicated to providing
                compassionate and personalized care. Our mission
                is to ensure that each resident feels valued, supported, and
                able to live their life to the fullest in a safe and nurturing
                environment.
              </p>
              <p style={{fontWeight:"Bold"}}>FL License #: 240214.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="kilimanjaro_part">
              <h5>Social Links</h5>
              <div className="kilimanjaro_blog_area">
                <ul className="kilimanjaro_social_links">
                <li>
                  <a
                    href="https://www.instagram.com/the.emeraldcaregroup/?utm_source=qr&igsh=c3czM3J4dmpyN3ox"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" aria-hidden="true"></i>{" "}
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/the_emeraldcare?t=yfkZxZerBeGvBlkhndiH_Q&s=08"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-x-twitter" aria-hidden="true"></i>{" "}
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/the-emerald-care/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" aria-hidden="true"></i>{" "}
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@the.emerald.caregroup?_t=8oZTUJ2a0E2&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-tiktok" aria-hidden="true"></i>{" "}
                    Tiktok
                  </a>
                </li>
              </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="kilimanjaro_part">
              <h5>Quick Contact</h5>
              <div className="kilimanjaro_single_contact_info">
                <h5>Phone:</h5>
                <p>
                (813)219-2212 <br />
                </p>
                <br />
                <br />
              </div>
              <div className="kilimanjaro_single_contact_info">
                <h5>Email:</h5>
                <p>
                  admin@theemeraldcaregroup.com <br />{" "}
                  theemeraldcaregroup@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="kilimanjaro_bottom_header_one section_padding_50 text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p>
              © All Rights Reserved by{" "}
              <span href="#">
                The Emerald Care Group |
                <i className="fab fa-love"></i>
              </span>
              &nbsp; FL License #: 240214.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
