// AboutUs.js
import React from "react";
import OtherOpportunities from "./components/OtherOpportunities";
import image1 from "./images/slider-1.jpg";
import image2 from "./images/img_2.jpg";
import image3 from "./images/staff_1.jpg";
import image4 from "./images/staff_2.jpg";
import image5 from "./images/staff_3.jpg";
import image6 from "./images/staff_4.jpg";
import image7 from "./images/img_3.jpg";
import MissionsCard from "./components/MissionsCard";
import CaregiversInfo from "./components/CaregiversInfo";
import SiteSection2 from "./components/SiteSection2";
import TeamComponent from "./components/TeamComponent";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const teamMembers = [
    {
      image: image3,
      name: "Rich Kase",
      position: "Owner",
      description: `Rich has spent the last 28 years working with Seniors in Skilled Nursing Centers, Assisted Living Facilities,
      Independent Living Centers, and Home Health Agencies. A graduate from the University of Connecticut, he obtained a 
      master’s in business administration and is a licensed Nursing Home Administrator. He is active legislatively in areas that
      protect the quality of care for our nation’s seniors. He is proud to call Florida home as a 23-year Hillsborough county
      resident.`,
    },
    {
      image: image4,
      name: "Michelle Kase",
      position: "Owner",
      description: `Michelle comes to FirstLight with 18 years of medical experience. She graduated from Villanova University as a 
      Registered Nurse and has worked in hospitals, outpatient surgical centers, and physicians’ offices. After taking time off to
      be a stay-at-home mom, Michelle was excited to join her friends to lend her medical expertise to FirstLight of Southern
      Hillsborough County. Outside of work, you can find Michelle with her family, fishing, boating, and taking advantage of
      the Florida lifestyle.`,
    },
    {
      image: image5,
      name: "Janice Bayruns",
      position: "Owner",
      description: `Janice has worked in healthcare for over 20 years including previous experience in home healthcare and skilled nursing. 
      As a caregiver to her father for the past 17 years, she relied extensively on non-medical home care services to keep him home,
      independent, and with a high quality of life. In her free time, Janice loves the Florida outdoors and spends time fishing, 
      scuba diving, kayaking, and sharing photos of her adventures with her dad.`,
    },
  ];
  return (
    <div>
      {/* Slide Item Section */}
      <div
        className="slide-item overlay"
        style={{ backgroundImage: `url(${image1})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-9 mx-auto mt-4 pt-4 d-flex flex-column justify-content-center align-items-center text-center">
              <h3 className="text-white ">About Us</h3>
              <p className="text-white">
                Welcome to the Emerald Care Group. At the Emerald Care Group, we
                are dedicated to providing compassionate and personalized care
                to individuals in need of support and assistance in a
                comfortable and nurturing home environment. Our mission is to
                enhance the quality of life for our residents by delivering
                comprehensive, high-quality health and personal care services.
              </p>
              <p>
                <Link to="/contact" className="btn btn-primary">
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Count Numbers Section */}
      <MissionsCard />
    </div>
  );
};

export default AboutUs;
