import React from "react";
import image from './images/img_3_sq.jpg';

export default function Testimonials() {
  return (
    <>
      <div
        className="slide-item overlay"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 align-self-center">
              <h1 className="heading mb-3">What Our Clients Say</h1>
              <p className="lead text-white">
                At Emerald Care Group, we pride ourselves on providing exceptional care and support. Here’s what our clients have to say about their experience with our dedicated team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
