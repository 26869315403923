import React, { useState, useEffect } from 'react';
import './App.css';
import accessibility from './images/svg/accessibility.svg';
import closecircle from './images/svg/close-one-svgrepo-com.svg';

const AccessibilityWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [textSize, setTextSize] = useState('normal');
  const [textStyle, setTextStyle] = useState('normal');
  const [highContrast, setHighContrast] = useState(false);
  const [lineHeight, setLineHeight] = useState('normal');
  const [fontWeight, setFontWeight] = useState('normal');
  const [isReading, setIsReading] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('large-text', textSize === 'large');
    document.body.classList.toggle('serif-text', textStyle === 'serif');
    document.body.classList.toggle('high-contrast', highContrast);
    document.body.classList.toggle('increased-line-height', lineHeight === 'increased');
    document.body.classList.toggle('bold-text', fontWeight === 'bold');

    return () => {
      document.body.classList.remove('large-text');
      document.body.classList.remove('serif-text');
      document.body.classList.remove('high-contrast');
      document.body.classList.remove('increased-line-height');
      document.body.classList.remove('bold-text');
    };
  }, [textSize, textStyle, highContrast, lineHeight, fontWeight]);

  const toggleWidget = () => {
    setIsOpen(prev => !prev);
  };

  const increaseTextSize = () => {
    setTextSize(prev => (prev === 'normal' ? 'large' : 'normal'));
  };

  const toggleTextStyle = () => {
    setTextStyle(prev => (prev === 'normal' ? 'serif' : 'normal'));
  };

  const toggleHighContrast = () => {
    setHighContrast(prev => !prev);
  };

  const toggleLineHeight = () => {
    setLineHeight(prev => (prev === 'normal' ? 'increased' : 'normal'));
  };

  const toggleFontWeight = () => {
    setFontWeight(prev => (prev === 'normal' ? 'bold' : 'normal'));
  };

  const toggleTextToSpeech = () => {
    if (isReading) {
      speechSynthesis.cancel(); // Stop reading
    } else {
      const utterance = new SpeechSynthesisUtterance(document.body.innerText);
      utterance.onend = () => setIsReading(false); // Set reading state to false when done
      speechSynthesis.speak(utterance);
    }
    setIsReading(prev => !prev);
  };

  const resetSettings = () => {
    setTextSize('normal');
    setTextStyle('normal');
    setHighContrast(false);
    setLineHeight('normal');
    setFontWeight('normal');
    if (isReading) {
      speechSynthesis.cancel(); // Stop reading if active
      setIsReading(false);
    }
  };

  return (
    <div>
      <button 
        className="widget-toggle-button circle"
        onClick={toggleWidget}
      >
        {isOpen ? <img src={closecircle} width={"70px"}/> : <img src={accessibility} width={"70px"}/>}
      </button>
      {isOpen && (
        <div className="accessibility-widget">
          <button onClick={increaseTextSize} className="widget-button">
            <i className="fas fa-text-height"></i>
            {textSize === 'normal' ? ' Increase Text Size' : ' Normal Text Size'}
          </button>
          <button onClick={toggleTextStyle} className="widget-button">
            <i className="fas fa-font"></i>
            {textStyle === 'normal' ? ' Serif Text' : ' Sans-serif Text'}
          </button>
          <button onClick={toggleHighContrast} className="widget-button">
            <i className="fas fa-tachometer-alt"></i>
            {highContrast ? ' Disable High Contrast' : ' Enable High Contrast'}
          </button>
          <button onClick={toggleLineHeight} className="widget-button">
            <i className="fas fa-arrows-alt-v"></i>
            {lineHeight === 'normal' ? ' Increase Line Height' : ' Normal Line Height'}
          </button>
          <button onClick={toggleFontWeight} className="widget-button">
            <i className="fas fa-bold"></i>
            {fontWeight === 'normal' ? ' Bold Text' : ' Normal Text Weight'}
          </button>
          <button onClick={toggleTextToSpeech} className="widget-button">
            <i className="fas fa-volume-up"></i>
            {isReading ? ' Stop Reading' : ' Read Text Aloud'}
          </button>
          <button onClick={resetSettings} className="widget-button">
            <i className="fas fa-undo"></i>
            Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default AccessibilityWidget;