// Import libraries
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/themes/base/all.css'; // Import jQuery UI CSS if needed
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; // Import the CSS
import Aos from 'aos';
import $ from 'jquery';

// Initialize AOS
Aos.init({
    duration: 800,
    easing: 'slide',
    once: true
});

$(document).ready(function() {
    // "use strict";

    // Loader
    $(".loader").delay(1000).fadeOut("slow");
    $("#overlayer").delay(1000).fadeOut("slow");

    // Site Menu Clone
    (function siteMenuClone() {
        $('.js-clone-nav').each(function() {
            $(this).clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
        });

        setTimeout(function() {
            var counter = 0;
            $('.site-mobile-menu .has-children').each(function() {
                $(this).prepend('<span class="arrow-collapse collapsed">').find('.arrow-collapse').attr({
                    'data-toggle': 'collapse',
                    'data-target': '#collapseItem' + counter
                }).end().find('> ul').attr({
                    'class': 'collapse',
                    'id': 'collapseItem' + counter
                });
                counter++;
            });
        }, 1000);

        $('body').on('click', '.arrow-collapse', function(e) {
            var $this = $(this);
            $this.toggleClass('active', !$this.closest('li').find('.collapse').hasClass('show'));
            e.preventDefault();
        });

        $(window).resize(function() {
            if ($(this).width() > 768 && $('body').hasClass('offcanvas-menu')) {
                $('body').removeClass('offcanvas-menu');
            }
        });

        $('body').on('click', '.js-menu-toggle', function(e) {
            e.preventDefault();
            $('body').toggleClass('offcanvas-menu');
            $(this).toggleClass('active');
        });

        $(document).mouseup(function(e) {
            if (!$(".site-mobile-menu").is(e.target) && $(".site-mobile-menu").has(e.target).length === 0 && $('body').hasClass('offcanvas-menu')) {
                $('body').removeClass('offcanvas-menu');
            }
        });
    })();

    // Plus/Minus Buttons
    (function sitePlusMinus() {
        $('.js-btn-minus').on('click', function(e) {
            e.preventDefault();
            var $input = $(this).closest('.input-group').find('.form-control');
            $input.val(Math.max(0, parseInt($input.val()) - 1));
        });
        $('.js-btn-plus').on('click', function(e) {
            e.preventDefault();
            var $input = $(this).closest('.input-group').find('.form-control');
            $input.val(parseInt($input.val()) + 1);
        });
    })();

    // Slider Range
    // (function siteSliderRange() {
    //     $("#slider-range").slider({
    //         range: true,
    //         min: 0,
    //         max: 500,
    //         values: [75, 300],
    //         slide: function(event, ui) {
    //             $("#amount").val("$" + ui.values[0] + " - $" + ui.values[1]);
    //         }
    //     });
    //     $("#amount").val("$" + $("#slider-range").slider("values", 0) + " - $" + $("#slider-range").slider("values", 1));
    // })();

    // Carousel
    // (function siteCarousel() {
    //     if ($('.nonloop-block-13').length > 0) {
    //         $('.nonloop-block-13').owlCarousel({
    //             center: false,
    //             items: 1,
    //             loop: true,
    //             stagePadding: 0,
    //             margin: 0,
    //             autoplay: true,
    //             nav: true,
    //             navText: ['<span class="icon-arrow_back">', '<span class="icon-arrow_forward">'],
    //             responsive: {
    //                 600: { items: 2 },
    //                 1000: { items: 3 },
    //                 1200: { items: 4 }
    //             }
    //         });
    //     }

    //     $('.single-text').owlCarousel({
    //         center: false,
    //         items: 1,
    //         loop: true,
    //         autoplay: true,
    //         pauseOnHover: false,
    //         nav: false,
    //         smartSpeed: 1000
    //     });

    //     $('.slide-one-item').owlCarousel({
    //         center: false,
    //         items: 1,
    //         loop: true,
    //         autoplay: true,
    //         smartSpeed: 1000,
    //         pauseOnHover: false,
    //         nav: true,
    //         navText: ['<span class="icon-keyboard_arrow_left">', '<span class="icon-keyboard_arrow_right">']
    //     });

    //     $('.slide-one-item-alt').owlCarousel({
    //         center: false,
    //         items: 1,
    //         loop: true,
    //         autoplay: true,
    //         smartSpeed: 1000,
    //         pauseOnHover: true,
    //         mouseDrag: false,
    //         touchDrag: false
    //     });

    //     $('.slide-one-item-alt-text').owlCarousel({
    //         center: false,
    //         items: 1,
    //         loop: true,
    //         autoplay: true,
    //         smartSpeed: 1000,
    //         pauseOnHover: true,
    //         mouseDrag: false,
    //         touchDrag: false
    //     });

    //     $('.custom-next').click(function(e) {
    //         e.preventDefault();
    //         $('.slide-one-item-alt').trigger('next.owl.carousel');
    //         $('.slide-one-item-alt-text').trigger('next.owl.carousel');
    //     });

    //     $('.custom-prev').click(function(e) {
    //         e.preventDefault();
    //         $('.slide-one-item-alt').trigger('prev.owl.carousel');
    //         $('.slide-one-item-alt-text').trigger('prev.owl.carousel');
    //     });
    // })();

    // Stellar Parallax
    // (function siteStellar() {
    //     $(window).stellar({
    //         responsive: false,
    //         parallaxBackgrounds: true,
    //         parallaxElements: true,
    //         horizontalScrolling: false,
    //         hideDistantElements: false,
    //         scrollProperty: 'scroll'
    //     });
    // })();

    // Countdown
    // (function siteCountDown() {
    //     $('#date-countdown').countdown('2020/10/10', function(event) {
    //         $(this).html(event.strftime(''
    //             + '<span class="countdown-block"><span class="label">%w</span> weeks </span>'
    //             + '<span class="countdown-block"><span class="label">%d</span> days </span>'
    //             + '<span class="countdown-block"><span class="label">%H</span> hr </span>'
    //             + '<span class="countdown-block"><span class="label">%M</span> min </span>'
    //             + '<span class="countdown-block"><span class="label">%S</span> sec</span>'));
    //     });
    // })();

    // Date Picker
    (function siteDatePicker() {
        if ($('.datepicker').length > 0) {
            $('.datepicker').datepicker();
        }
    })();

    // Sticky Header
    // (function siteSticky() {
    //     $(".js-sticky-header").sticky({topSpacing: 0});
    // })();

    // One Page Navigation
    (function OnePageNavigation() {
        $("body").on("click", ".main-menu li a[href^='#'], .smoothscroll[href^='#'], .site-mobile-menu .site-nav-wrap li a", function(e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                'scrollTop': $(hash).offset().top
            }, 600, 'easeInOutExpo', function() {
                window.location.hash = hash;
            });
        });
    })();

    // Scroll Effects
    (function siteScroll() {
        $(window).scroll(function() {
            $('.js-sticky-header').toggleClass('shrink', $(this).scrollTop() > 100);
        });
    })();

    // Isotope
    // (function siteIstotope() {
    //     var $container = $('#posts').isotope({
    //         itemSelector: '.item',
    //         isFitWidth: true
    //     });

    //     $(window).resize(function() {
    //         $container.isotope({ columnWidth: '.col-sm-3' });
    //     });

    //     $('#filters').on('click', 'button', function() {
    //         var filterValue = $(this).attr('data-filter');
    //         $container.isotope({ filter: filterValue });
    //         $('#filters button').removeClass('active');
    //         $(this).addClass('active');
    //     });
    // })();

    // Fancybox
    $('.fancybox').on('click', function() {
        var visibleLinks = $('.fancybox');
        $.fancybox.open(visibleLinks, {}, visibleLinks.index(this));
        return false;
    });

    // Counter
    // (function counter() {
    //     $('.count-numbers').waypoint(function(direction) {
    //         if (direction === 'down' && !$(this.element).hasClass('ut-animated')) {
    //             $('.counter > .number').each(function() {
    //                 var $this = $(this),
    //                     num = $this.data('number');
    //                 $this.animateNumber({
    //                     number: num,
    //                     numberStep: $.animateNumber.numberStepFactories.separator(',')
    //                 }, 7000);
    //             });
    //         }
    //     }, { offset: '95%' });
    // })();
});