import React, { useEffect, useState } from 'react';
import comingImage from "./images/ms-schofield.jpg";
import backgroundImg from "./images/pricing-caregiver-walker.jpg"; // Importing the background image
import './App.css';

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const countDownDate = new Date("Sep 20, 2024 00:00:00").getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft("EXPIRED");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <header className="sky_bg">
      {/* <div className="barbeque">
        <img src={comingImage} alt="Barbeque" className="img-fluid" />
      </div> */}

      {/* Main Info */}
      <div className="hero d-flex flex-column align-items-center">
        <p style={{color:'white'}}>The Emerald Care Group</p>
        <h1>Coming Soon</h1>
        <hr />
        <p style={{color:'white'}} id="launch">{timeLeft}</p>
        <a href="/coming-contact" className="btn btn-primary">Contact Us</a>
      </div>

      {/* Vapour */}
      <div className="wrap_steam">
        <div id="steam" className="steam1"></div>
        <div id="steam" className="steam2"></div>
        <div id="steam" className="steam3"></div>
        <div id="steam" className="steam4"></div>
        <div id="steam" className="steam5"></div>
      </div>
    </header>
  );
};

export default ComingSoon;
