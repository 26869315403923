import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import image from "./images/careers-caregiver-newspaper.jpg";
import axios from 'axios';

const ServicePage = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    subject: '',
    message: ''
  });

  const { serviceName } = useParams();

  // Mock content for each service, you can replace it with actual data
  const serviceContent = {
    "All Services": "Here is the overview of all services we offer. At The Emerald Care Group, we provide comprehensive care solutions tailored to meet the unique needs of our clients. Whether it's rehabilitation, mental health support, or assistance with daily activities, our team is committed to delivering personalized and compassionate care to help our clients thrive.",
    "Behavioral Health": "Behavioral Health services focus on mental well-being by addressing emotional and behavioral challenges. Our team of qualified counselors and therapists provides therapy and support to help individuals manage conditions like anxiety, depression, and stress. These services empower clients to develop coping strategies and improve their emotional health.",
    "Physical Therapy": "Physical Therapy (PT) helps recover physical movement after injury, surgery, or illness. Our licensed physical therapists create personalized plans to improve mobility, strength, and balance, ultimately enhancing independence and long-term well-being.",
    "Occupational Therapy": "Occupational Therapy (OT) assists with daily living activities, helping individuals regain or develop essential self-care and work skills. Whether it's relearning how to dress, cook, or use adaptive equipment, OT maximizes independence and quality of life.",
    "Psychiatric Services": "Psychiatric Services provide mental health assessments, diagnoses, and treatments. Our psychiatrists manage conditions like schizophrenia, bipolar disorder, and depression through medication and therapy, ensuring a supportive recovery environment.",
    "Vocational Rehabilitation": "Vocational Rehabilitation Programs help individuals regain job skills or develop new ones. We provide job training, resume writing, and interview coaching to foster confidence and support long-term, meaningful employment for those overcoming challenges.",
    "Trauma-Informed Care": "Trauma-Informed Care focuses on helping trauma survivors by creating a safe and supportive environment. We address the emotional and psychological impact of trauma, guiding individuals toward healing and recovery with compassion and empowerment.",
    "Community Integration": "Community Integration promotes social inclusion through structured activities and group outings. We support individuals in building meaningful relationships and engaging in social environments, enhancing their mental and emotional well-being.",
    "Family Support Services": "Family Support Services offer guidance and resources to families caring for loved ones with health challenges. We provide emotional support, education, and stress management strategies to help families navigate the demands of caregiving.",
    "Adaptive Equipment Training": "Adaptive Equipment Training teaches individuals how to use assistive devices like wheelchairs, walkers, and communication aids. Our training improves mobility, independence, and confidence, ensuring clients can navigate their surroundings with ease."
  };

  const [responseMessage, setResponseMessage] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/submit', formData);
      setResponseMessage(response.data.message);
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      setResponseMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <div className="slide-item overlay" style={{ backgroundImage: `url(${image})` }}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10 align-self-center">
            <h3 className="text-white mb-3">{serviceName}</h3>
              <p className=" text-white">{serviceContent[serviceName] || "Service not found"}</p>
            </div>
          </div>
        </div>  
      </div>

      <div className="site-section">
        <div className="container">
        <h1 className='py-4'>Contact Us</h1>
          <div className="row">
            <div className="col-lg-8 mb-5">
              <form onSubmit={handleSubmit}>
              {responseMessage && <div className="alert alert-success">{responseMessage}</div>}
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="fname">First Name</label>
                    <input type="text" id="fname" className="form-control" value={formData.fname} onChange={handleChange} />
                  </div>
                  <div className="col-md-6">
                    <label className="text-black" htmlFor="lname">Last Name</label>
                    <input type="text" id="lname" className="form-control" value={formData.lname} onChange={handleChange} />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="email">Email</label> 
                    <input type="email" id="email" className="form-control" value={formData.email} onChange={handleChange} />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="subject">Subject</label> 
                    <input type="text" id="subject" className="form-control" value={formData.subject} onChange={handleChange} />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="message">Message</label> 
                    <textarea name="message" id="message" cols="30" rows="7" className="form-control" value={formData.message} onChange={handleChange} placeholder="Write your notes or questions here..."></textarea>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <input type="submit" value="Send Message" className="btn btn-primary text-white" />
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-3 ml-auto">
              <div className="mb-3">
                <p className="mb-0 font-weight-bold text-black">Address</p>
                <p className="mb-4">Box 10653 Zip, Tampa FL 33679</p>
                <p className="mb-0 font-weight-bold text-black">Phone</p>
                <p className="mb-4"><a href="tel:+254796975290">1(813)219-2212</a></p>
                <p className="mb-0 font-weight-bold text-black">Email Address</p>
                <p className="mb-0"><a href="mailto:admin@theemeraldcaregroup.com">admin@theemeraldcaregroup.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;