import React from 'react';
import image1 from '../images/elderser.png';
import './stylesComponents.css'; // Make sure to include your styles

const Pricing = () => (
    <>
    {/* Slide Item Section */}
    <div
        className="slide-item overlay"
        style={{ backgroundImage: `url(${image1})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-9 align-self-center">
              <h1 className="heading mb-3">Pricing</h1>
              <p className="lead text-white mb-5">
                Welcome to the Emerald Care Group. At the Emerald Care Group, we
                are dedicated to providing compassionate and personalized care
                to individuals in need of support and assistance in a
                comfortable and nurturing home environment. Our mission is to
                enhance the quality of life for our residents by delivering
                comprehensive, high-quality health and personal care services.
              </p>
              <p>
                <button
                  className="btn btn-primary"
                  // onClick={() => alert("Button clicked")}
                >
                  Get In Touch
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    <div className="pricing-section site-section bg-light cssanimation fadeInBottom">
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8">
          <h2 className="heading mb-3">Choose Quality Home Care and Peace of Mind</h2>
          <p>
            We know how important evaluating the cost of senior care and home care assistance can be. But cost is only one thing to consider. Compassionate care and quality service delivered by trusted professionals can bring comfort and support to your family.
          </p>
          <p className="mb-4">
            The most common way to pay for home care is private pay. But some services may be covered by a variety of benefits and programs. 
          </p>
          <p>
            <a href="/request-pricing" className="btn btn-primary">Click here to request personalized pricing for the services you need to help keep your loved one in the place they call home</a>
          </p>
        </div>
      </div>
    </div>
  </div>
    </>
  
);

export default Pricing;