import React, { useState } from "react";
import image from "./images/slider-2.jpg";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5000/submit",
        formData
      );
      setResponseMessage(response.data.message);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <div
        className="slide-item overlay"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 align-self-center">
              <h3 className="text-white mb-3">Contact Us</h3>
              <p className="text-white">
                We'd love to hear from you! Reach out to us with any questions
                or concerns.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-5">
              <form onSubmit={handleSubmit}>
                {responseMessage && (
                  <div className="alert alert-success">{responseMessage}</div>
                )}
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="fname">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="fname"
                      className="form-control"
                      value={formData.fname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="text-black" htmlFor="lname">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lname"
                      className="form-control"
                      value={formData.lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="subject">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="form-control"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="7"
                      className="form-control"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Write your notes or questions here..."
                    ></textarea>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary text-white"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-4 ml-lg-auto">
              <ul className="list-unstyled contact-details">
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">Address</p>
                  <p>
                    <a
                      href="tel:+18132192212"
                      className="text-muted contact-link"
                    >
                      P.O Box 10653, Tampa FL 33679
                    </a>
                  </p>
                </li>
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">Phone</p>
                  <p>
                    <a
                      href="tel:+18132192212"
                      className="text-muted contact-link"
                    >
                      (813)219-2212
                    </a>
                  </p>
                </li>
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">
                    Email Address
                  </p>
                  <p>
                    <a
                      href="mailto:admin@theemeraldcaregroup.com"
                      className="text-muted contact-link"
                    >
                      admin@theemeraldcaregroup.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
