import React from "react";
import image1 from "../images/svg/svg/001-elderly.svg";
import image2 from "../images/svg/svg/002-elderly-1.svg";
import image3 from "../images/svg/svg/003-rocking-chair.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeatureV1 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <div className="feature-v1">
        <Slider {...settings}>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex pagination-item h-100">
              <span className="icon-wrap">
                <img
                  src={image1}
                  alt="Behavioral Health"
                  className="img-fluid"
                />
              </span>
              <div>
                <span className="subheading">Comprehensive Services</span>
                <h3 className="heading">
                  Behavioral Health Support and Counseling
                </h3>
                <a href="/services" className="small">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex pagination-item h-100">
              <span className="icon-wrap">
                <img
                  src={image2}
                  alt="Physical Therapy"
                  className="img-fluid"
                />
              </span>
              <div>
                <span className="subheading">Comprehensive Services</span>
                <h3 className="heading">
                  Physical Therapy and Rehabilitation
                </h3>
                <a href="/services" className="small">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex pagination-item h-100">
              <span className="icon-wrap">
                <img
                  src={image3}
                  alt="Occupational Therapy"
                  className="img-fluid"
                />
              </span>
              <div>
                <span className="subheading">Comprehensive Services</span>
                <h3 className="heading">Occupational Therapy Programs</h3>
                <a href="/services" className="small">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="feature-v2 cssanimation fadeInBottom">
        <div className="d-lg-flex align-items-center w-100">
          <div className="d-flex pagination-item h-100">
            <span className="icon-wrap">
              <img
                src={image1}
                style={{ width: "25vh" }}
                alt="Behavioral Health"
                className="img-fluid"
              />
            </span>
            <div>
              <span className="subheading">Comprehensive Services</span>
              <h3 className="heading">
                Behavioral Health Support and Counseling
              </h3>
              <a href="/services" className="small">
                Learn More
              </a>
            </div>
          </div>
          <div className="d-flex pagination-item h-100">
            <span className="icon-wrap">
              <img
                src={image2}
                style={{ width: "25vh" }}
                alt="Physical Therapy"
                className="img-fluid"
              />
            </span>
            <div>
              <span className="subheading">Comprehensive Services</span>
              <h3 className="heading">
                Physical Therapy and Rehabilitation
              </h3>
              <a href="/services" className="small">
                Learn More
              </a>
            </div>
          </div>
          <div className="d-flex pagination-item h-100">
            <span className="icon-wrap">
              <img
                src={image3}
                style={{ width: "25vh" }}
                alt="Occupational Therapy"
                className="img-fluid"
              />
            </span>
            <div>
              <span className="subheading">Comprehensive Services</span>
              <h3 className="heading">Occupational Therapy Programs</h3>
              <a href="/services" className="small">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureV1;