import React, { useEffect, useState } from 'react';
import image from './images/staff_3.jpg';
const ContactUsData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    fetch('http://localhost:5000/get-data')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
        <div className="slide-item overlay" style={{ backgroundImage: `url(${image})` }}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 align-self-center">
              <h1 className="heading mb-3">Submitted Contact Us Data</h1>
            </div>
          </div>
        </div>  
      </div>
      {data.length > 0 ? (
        <table className="table table-striped table-hover table-bordered align-middle text-center">
        <thead className="table-dark">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Subject</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item['First Name']}</td>
              <td>{item['Last Name']}</td>
              <td>{item['Email']}</td>
              <td>{item['Subject']}</td>
              <td>{item['Message']}</td>
            </tr>
          ))}
        </tbody>
      </table>
      ) : (
        <p className="text-center text-muted">No data available</p>
      )}
    </div>
  );
};

export default ContactUsData;