import React from "react";
import Slider from "react-slick";
import "./stylesComponents.css";
import missionImg from "../images/mission.jpg"; // Example image for mission
import valuesImg from "../images/values.jpg"; // Example image for values
import visionImg from "../images/vision.jpg"; // Example image for vision

// Mission component with an image
const Mission = ({ imgSrc, title, description, animationClass, iconClass }) => (
  <div className="col-12">
    <div className="row" style={{ padding: "5px" }}>
      <div className="col-md-12">
        <div className={`card ${animationClass}`}>
          <span>
            <div className="card-body">
              <div className="row d-lg-flex flex-lg-row align-items-center justify-content-left mb-4">
                <div className="col-md-5">
                  {/* Image at the top of the card */}
                  {imgSrc && (
                    <div className="card-img-top">
                      <img
                        src={imgSrc}
                        alt={title}
                        style={{ width: "100%", height: "50vh" }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-7 d-flex align-items-left">
                  <div className="row">
                    <div className="col-12">
                      {/* FontAwesome icon moved above the text */}
                      <center>
                      <i
                        className={`fa ${iconClass}`} // Replace with the appropriate icon class for each mission
                        style={{
                          fontSize: "40px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        aria-hidden="true"
                      ></i>
                      </center>
                    </div>
                    <div
                      className="col-12"
                      style={{ padding: "20px", textAlign: "left" }}
                    >
                      <h6>{title}</h6>
                      {Array.isArray(description) ? (
                        <ul>
                          {description.map((item, index) => (
                            <li key={index} style={{ color: "black" }}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>{description}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
);

const MissionsCard = () => {
  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <div
      className="container"
      style={{ marginTop: "10vh", marginBottom: "10vh" }}
    >
      <div className="row">
        <div className="col-md-12">
          {/* Slider for the Mission Cards */}
          <Slider {...settings}>
            <div>
              <Mission
                title="Our Mission"
                description="To provide compassionate, comprehensive care to individuals with Traumatic Brain Injuries, helping them achieve their potential and reintegrate into society with dignity."
                animationClass="cssanimation fadeInBottom"
                imgSrc={missionImg} // Pass mission image here
                iconClass = "fa-bullseye"
              />
            </div>
            <div>
              <Mission
                title="Our Values"
                description={[
                  "Compassion: We treat all clients with empathy and kindness.",
                  "Respect: We honor the dignity of each individual.",
                  "Excellence: We strive for the highest standards in care and services.",
                  "Integrity: We act with honesty and transparency in all our dealings.",
                  "Innovation: We embrace new methods and technologies to improve client outcomes.",
                  "Family Inclusion: We believe in the importance of family involvement in the recovery process.",
                ]}
                animationClass="cssanimation2 fadeInBottom2"
                imgSrc={valuesImg} // Pass values image here
                iconClass = "fa-hands"
              />
            </div>
            <div>
              <Mission
                title="Our Vision"
                description="To be the leading provider of innovative and compassionate care for TBI clients, recognized for our dedication to client success and family inclusion."
                animationClass="cssanimation3 fadeInBottom3"
                imgSrc={visionImg} // Pass vision image here
                iconClass = "fa-eye"
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MissionsCard;
