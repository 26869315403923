import React, { useState, useEffect } from 'react';
import image1 from "../images/person_1.jpg";
import image2 from "../images/person_1.jpg";
import image3 from "../images/person_1.jpg";
import './stylesComponents.css';

const testimonials = [
  {
    id: 1,
    image: image1,
    quote: "The support and guidance provided by Emerald Care Group have been transformative for my son. The personalized approach has significantly improved his daily life.",
    author: "Emily Carter, Parent"
  },
  {
    id: 2,
    image: image2,
    quote: "Emerald Care Group's team has helped my daughter gain confidence and independence. Their dedication to her development is truly commendable.",
    author: "Michael Brown, Parent"
  },
  {
    id: 3,
    image: image3,
    quote: "Thanks to Emerald Care Group, my sibling has access to crucial support services that have greatly enhanced their learning and daily living skills.",
    author: "Samantha Green, Sibling"
  }
];

const TestimonialSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="site-section bg-light cssanimation fadeInBottom">
      <div className="container">
        <h1 className='text-center pb-4'>What Families Are Saying</h1>
        <div className="testimonial-slider p-4">
          <div className='p-2'></div>
          <div className="testimonial text-center">
            <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].author} className="img-fluid" />
            <blockquote>
              <p className="quote">
                {testimonials[currentIndex].quote}
              </p>
              <cite className="author">{testimonials[currentIndex].author}</cite>
            </blockquote>
          </div>
          <button className="slider-button prev" onClick={goToPrevious}>
            &lt;
          </button>
          <button className="slider-button next" onClick={goToNext}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
