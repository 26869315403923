import React from 'react';
import './stylesComponents.css'; // Add custom styles for the services section
import ServicesCard from './ServicesCard';
import image from '../images/services.jpg';
import caseManagement from '../images/bh.jpg';
import medicationManagement from '../images/mma.jpg';
import progressReporting from '../images/hr.jpg';
import behavioralHealth from '../images/bh.jpg';
import physicalTherapy from '../images/pt.jpg';
import occupationalTherapy from '../images/ot.jpg';
import socialRecreational from '../images/soc.jpg';
import medicalTransportation from '../images/medtrans.jpg';
import staffSupport from '../images/staffsupport.jpg';
import billPaying from '../images/bill.jpg';
import familyInclusion from '../images/fam.jpg';

// Example images for cards, you can replace with actual images
const images = {
    caseManagement: caseManagement,
    medicationManagement: medicationManagement,
    progressReporting: progressReporting,
    behavioralHealth: behavioralHealth,
    physicalTherapy: physicalTherapy,
    occupationalTherapy: occupationalTherapy,
    socialRecreational: socialRecreational,
    medicalTransportation: medicalTransportation,
    staffSupport: staffSupport,
    billPaying: billPaying,
    familyInclusion: familyInclusion
};

const services = [
    {
      title: 'Behavioral Health Support and Counseling',
      description: 'This service provides psychological support and counseling to help manage emotional and behavioral challenges, improve mental health, and enhance overall well-being.',
      imageUrl: images.behavioralHealth
    },
    {
      title: 'Medication Management and Administration',
      description: 'We provide assistance with pharmacy services to ensure clients receive their medications correctly and on time.',
      imageUrl: images.medicationManagement
    },
    {
      title: 'Physical Therapy and Rehabilitation',
      description: 'Physical therapy focuses on improving physical function and mobility through exercises to enhance strength, balance, and coordination.',
      imageUrl: images.physicalTherapy
    },
    {
      title: 'Occupational Therapy Programs',
      description: 'Occupational therapy helps individuals develop or regain the skills needed for daily living and work, improving fine motor skills and cognitive function.',
      imageUrl: images.occupationalTherapy
    },
    {
      title: 'Social and Recreational Activities',
      description: 'Providing opportunities for individuals to engage in enjoyable activities such as group outings and hobbies.',
      imageUrl: images.socialRecreational
    },
    {
      title: 'Transportation to Medical Appointments',
      description: 'We ensure reliable transportation to and from medical appointments, therapy sessions, and other essential visits.',
      imageUrl: images.medicalTransportation
    },
    {
      title: 'Case Management',
      description: 'Our experienced case managers coordinate all aspects of care, ensuring each client receives personalized support.',
      imageUrl: images.caseManagement
    },
    {
      title: 'Monthly Progress Reporting',
      description: 'Regular updates on client progress are provided to keep families informed and involved in the recovery process.',
      imageUrl: images.progressReporting
    },
    {
      title: 'Bill Paying Assistance',
      description: 'We help clients manage their finances, including bill paying, to reduce stress and promote financial stability.',
      imageUrl: images.billPaying
    },
    {
      title: 'Family Inclusion Plan',
      description: 'We actively involve families in the care process, recognizing the critical role they play in the client’s recovery and well-being.',
      imageUrl: images.familyInclusion
    },
    {
      title: '24/7 Staff Support and Supervision',
      description: 'Providing round-the-clock staff support ensures clients have access to assistance whenever needed, ensuring safety and continuous care.',
      imageUrl: images.staffSupport
    }
];

const Services = () => (
  <section className="services" id='services'>
    <div className="slide-item overlay" style={{ backgroundImage: `url(${image})` }}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-6 align-self-center">
            <h3 className="text-white mb-3">Services</h3>
            <p className="text-white">We'd love to hear from you! Reach out to us with any questions or concerns.</p>
          </div>
        </div>
      </div>  
    </div>
    <div className="container py-5">
      <div className="row">
        <div className="col-12 text-center mb-5">
          <h2 className="display-4">Our Services</h2>
          <p className="lead">We offer a wide range of services tailored to meet the unique needs of our residents:</p>
        </div>
        {services.map((service, index) => (
          <div key={index} className="col-lg-4 col-md-6 mb-4 position-relative">
            <ServicesCard
              title={service.title}
              description={service.description}
              imageUrl={service.imageUrl}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Services;