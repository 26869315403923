import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ServicesCard = ({ title, description, imageUrl }) => (
  <div className="card">
    <img src={imageUrl} alt={title} className="card-img-top" />
    <div className="card-body">
      <h5 className="card-titles">{title}</h5>
      <p className="card-text">{description}</p>
      <div>
        <Link to='/contact' className='btn btn-primary'>Contact Us</Link>
      </div>
    </div>
     {/* Circular Icon Container */}
     
  </div>
);

ServicesCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default ServicesCard;
