import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Loader from "./components/Loader";
import Header from "./Header";
import SlideItem from "./components/SlideItem";
import Featurev1 from "./components/Featurev1";
import SiteSection from "./components/SiteSection";
import CountNumberSection from "./components/CountNumberSection";
import SiteSection2 from "./components/SiteSection2";
import CoverSection from "./components/CoverSection";
import TestimonialSection from "./components/TestimonialSection";
import OtherOpportunities from "./components/OtherOpportunities";
import AdultCareCenter from "./components/AdultCareCenter";
import NewsUpdates from "./components/NewsUpdates";
import Head from "./Head";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import NotFound from "./NotFound";
import AboutUs from "./About";
import TEstimonials from "./Testimonials";
import OurServices from "./components/OurServices";
import Pricing from "./components/Pricing";
import ComingSoon from "./ComingSoon";
import ComingContact from "./ComingContact";
import A11yWidget from "./A11yWidget";
import AccessibilityWidget from "./AccessibilityWidget";
import Services from "./components/Services";
import ContactUsData from "./ContactusData";
import Login from "./Login";
import ServicePage from "./ServicePage";
import './App.css';

const App = () => {
  // Target date: Sep 20, 2024 00:00:00
  const targetDate = new Date("Sep 20, 2024 00:00:00").getTime();
  const currentDate = new Date().getTime();

  return (
    <Router>
      <div>
        <Head />
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={currentDate >= targetDate ? "/home" : "/coming-soon"} replace />
            }
          />
          <Route
            path="/coming-soon"
            element={<ComingSoon />}
          />
          <Route
            path="/coming-contact"
            element={<ComingContact />}
          />
          <Route
            path="/home"
            element={
              <>
                <Loader />
                <div className="site-wrap">
                  <Header />
                  <SlideItem />
                  {/* <Featurev1 /> */}
                  {/* <SiteSection /> */}
                  {/* <OurServices /> */}
                  {/* <CountNumberSection /> */}
                </div>
                {/* <SiteSection2 /> */}
                {/* <CoverSection /> */}
                {/* <TestimonialSection /> */}
                {/* <OtherOpportunities /> */}
                {/* <AdultCareCenter /> */}
                <NewsUpdates />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Loader />
                <Header />
                <ContactUs />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Loader />
                <Header />
                <AboutUs />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Loader />
                <Header />
                <Login />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/services/:serviceName"
            element={
              <>
                <Loader />
                <Header />
                <ServicePage />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/contactus-data"
            element={
              <>
                <Loader />
                <Header />
                <ContactUsData />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <Loader />
                <Header />
                <Services />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Loader />
                <Header />
                <Pricing />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route
            path="/testimonial"
            element={
              <>
                <Loader />
                <Header />
                <TEstimonials />
                <AdultCareCenter />
                <TestimonialSection />
                <Footer />
                <AccessibilityWidget />
              </>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;