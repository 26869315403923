import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => (
  <Helmet>
    <title>The Emerald Care Group &mdash; Official Website</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display:wght@400;700&display=swap"
      rel="stylesheet"
    />
    <link rel="stylesheet" href="./fonts/icomoon/style.css" />
    <link rel="stylesheet" href="./css/bootstrap.min.css" />
    <link rel="stylesheet" href="./css/jquery-ui.css" />
    <link rel="stylesheet" href="./css/owl.carousel.min.css" />
    <link rel="stylesheet" href="./css/owl.theme.default.min.css" />
    <link rel="stylesheet" href="./css/jquery.fancybox.min.css" />
    <link rel="stylesheet" href="./css/bootstrap-datepicker.css" />
    <link rel="stylesheet" href="./fonts/flaticon/font/flaticon.css" />
    <link rel="stylesheet" href="./css/aos.css" />
    <link rel="stylesheet" href="./css/style.css" />
  </Helmet>
);

export default Head;
