import React from 'react';
import './App.css'; // Make sure to create a corresponding CSS file for styles

const ComingContact = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>Welcome to the emerald care group.</p>
      
      <p>
        At the emerald care group, we are dedicated to providing compassionate and personalized care 
        to individuals in need of support and assistance in a comfortable and nurturing home environment. 
        Our mission is to enhance the quality of life for our residents by delivering comprehensive, 
        high-quality health and personal care services.
      </p>

      <div className="address">
        <p><span>Address:</span> The Emerald, Tampa, FL</p>
      </div>
      
      <div className="address">
        <p><span>Email Address:</span> admin@theemeraldcaregroup.com</p>
      </div>

      <div className="phone">
        <p><span>Phone:</span> 1(813)219-2212</p>
      </div>

      <div className="hours">
        <p><span>Office Hours:</span> Monday - Friday, 9:00 AM - 5:00 PM</p>
      </div>

      <p>
        We look forward to hearing from you and will get back to you as soon as possible. 
        Thank you for choosing The Emerald Organization.
      </p>

      <a className="btn btn-primary" href="/">Go back home</a>
    </div>
  );
};

export default ComingContact;