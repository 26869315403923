import React, { useState } from "react";
import axios from "axios";
import image from './images/img_2.jpg';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5000/login", {
        email,
        password,
      });
      if (response.data.success) {
        setSuccessMessage("Login successful!");
        setErrorMessage("");
        // Redirect or do something upon successful login
      } else {
        setSuccessMessage("");
        setErrorMessage("Invalid email or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Server error. Please try again later.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="">
        <div className="slide-item overlay" style={{ backgroundImage: `url(${image})` }}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 align-self-center">
              <h1 className="heading mb-3">Login</h1>
              <p className="lead text-white">Log in to see contact details
              </p>
            </div>
          </div>
        </div>  
      </div>
      {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
      {successMessage && <p className="alert alert-success">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="container p-4">
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
