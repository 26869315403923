import React from 'react';
import aboutImage from "../images/about.png";

const AdultCareCenter = () => (
  <div className="site-section cssanimation fadeInBottom" style={{backgroundColor:'#0F2946'}}>
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 mb-5 mb-md-0">
          <img src={aboutImage} alt="Adult Care Center" className="img-fluid" />
        </div>
        <div className="col-md-6 col-lg-5 ml-auto">
          <div className="section-heading">
            <h2 className="heading mb-3 text-white">Adult Support and Development Center</h2>
            <p className="text-white">
              At Emerald Care Group, we specialize in providing tailored support and development programs for young adults with learning difficulties. Our focus is on enhancing their quality of life through compassionate care and personalized services.
            </p>
            <p className="mb-4 text-white">
              Our center is equipped with modern amenities and staffed by experienced professionals who are dedicated to supporting each individual's unique needs. We offer a variety of services including educational assistance, life skills training, and recreational activities aimed at fostering independence and personal growth.
            </p>
            <p className="text-white mb-5">
              <strong className="h3">&ldquo;Dedicated to empowering young adults through personalized care and support&rdquo;</strong>
            </p>
            <p>
              <a href="/services" className="btn btn-white">Our Services</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AdultCareCenter;
